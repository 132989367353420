import React from 'react';
import { useDispatch } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import type { ChallengeName } from 'amazon-cognito-identity-js';
import { Typography } from '@material-ui/core';

import TwoSidePanel from '../../../../components/TwoSidePanel';
import FederatedLogInPanel from '../common/FederatedLogIn';
import LogInForm from '../../../../components/LogInForm';
import { isSignUpEnabled } from '../../../../../../../helpers/functions/utils/appConfig';
import { prepareEmailAttribute } from '../../../../helpers/functions/authentication';
import { buildRedirectedFrom, getRedirectedFrom } from '../../../../helpers/functions/navigation';
import { getAuthLink } from '../../../../../../../helpers/navigation';
import { View } from '../../../../helpers/constants/authentication';
import { errorNotify } from '../../../../../../notifications/helpers/functions/notify';
import { CustomError } from '../../../../../../../helpers/functions/utils/errorHandling';
import { LoginFormFields } from '../../../../types/ui';
import useTokensBroker from '../../../../hooks/useTokensBroker';

import '../../../../components/common/index.scss';
import './index.scss';

const LogInPanel = ({
  onLoading,
}: {
  onLoading: (loading: boolean) => void;
}) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  const signUpEnabled = isSignUpEnabled();
  const [signInError, setSignInError] = React.useState<string | null>(null);
  const {
    sendSignInMessage,
    sendGetTokensMessage,
  } = useTokensBroker({
    onSignInSuccess: (challenge?: ChallengeName) => {
      if (challenge === 'NEW_PASSWORD_REQUIRED') {
        const redirectedFrom = getRedirectedFrom(location.search);

        onLoading(false);
        history.push({
          pathname: getAuthLink(View.newPassword),
          search: redirectedFrom ? buildRedirectedFrom(redirectedFrom) : '',
        });
      } else {
        sendGetTokensMessage();
      }
    },
    onSignInError: (error: string) => {
      setSignInError(error);
      onLoading(false);
    },
  });

  const handleLogInClick = async (formState: LoginFormFields) => {
    const preparedEmail = prepareEmailAttribute(formState.email);

    onLoading(true);
    sendSignInMessage(preparedEmail, formState.password);
  };

  const handleLogInUnknownError = (e: Error) => {
    errorNotify({
      error: new CustomError('[UI Login] Unable to login user.', {
        cause: e,
      }),
      message: e.message,
      dispatch,
    });
  };

  const renderPanel = () => {
    if (signUpEnabled) {
      return (
        <TwoSidePanel
          left={<FederatedLogInPanel />}
          right={(
            <LogInForm
              signUpEnabled={signUpEnabled}
              error={signInError}
              onLogInClick={handleLogInClick}
              onLogInUnknownError={handleLogInUnknownError}
            />
          )}
        />
      );
    }

    return (
      <LogInForm
        signUpEnabled={signUpEnabled}
        error={signInError}
        onLogInClick={handleLogInClick}
        onLogInUnknownError={handleLogInUnknownError}
      />
    );
  };

  return (
    <div className="authentication-panel log-in-panel">
      <Typography className="authentication-panel__header" variant="h1">
        Log in
      </Typography>
      {renderPanel()}
    </div>
  );
};

export default LogInPanel;
