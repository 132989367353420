import { isCleanedStatus } from '../../../../../helpers/functions/entities/dataset';
import { stringDateComparator } from '../../../../../helpers/functions/utils/date';
import { comparator } from '../../../../../helpers/functions/utils/string';
import type { TransformedDataset } from '../../../../../helpers/types/dataset';

export const datasetsComparator = (a: TransformedDataset, b: TransformedDataset): number => {
  if (a.operationStartDate && b.operationStartDate) {
    return stringDateComparator(a.operationStartDate, b.operationStartDate);
  }

  // Sort in descending order
  return comparator(a.name || '', b.name || '') * -1;
};

export const datasetsGroupComparator = (a: TransformedDataset, b: TransformedDataset): number => {
  if (!isCleanedStatus(a.status)) {
    return 1;
  }

  if (!isCleanedStatus(b.status)) {
    return -1;
  }

  if (a.fieldCoverage && b.fieldCoverage) {
    if (a.fieldCoverage < b.fieldCoverage) {
      return 1;
    } if (a.fieldCoverage > b.fieldCoverage) {
      return -1;
    }
  }

  if (a.number && b.number) {
    if (a.number < b.number) {
      return 1;
    } if (a.number > b.number) {
      return -1;
    }
  }

  if (a.operationFileDate && b.operationFileDate) {
    return stringDateComparator(a.operationFileDate, b.operationFileDate);
  }

  return 0;
};
