import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import i18n from 'i18next';
import produce from 'immer';
import type { FeatureCollection, MultiPolygon } from '@turf/helpers';
import { closeSnackbar } from 'notistack';

import { isRequiredDataLoaded } from '../../helpers/components/legend';
import { isAssetRequested } from '../../helpers/functions/entities/assets';
import { getAssetByUuid } from '../../helpers/functions/entities/lookup';
import {
  addAsAppliedDataset,
  addEquationMap,
  addSatelliteImage,
  addSoilDataset,
  addTopographyMap,
  addVectorAnalysisMap,
  addYieldDataset,
  deleteAsAppliedDatasets,
  deleteSatelliteImages,
  deleteSoilDatasets,
  deleteTopographyMaps,
  deleteVectorAnalysisMaps,
  deleteEquationMaps,
  deleteYieldDatasets,
} from '../subscription/subscriptionSlice';
import {
  selectFarmUuid,
  selectFieldUuid,
  selectAllLoading,
  selectSatelliteLoading,
  selectIsFieldAndZonesMapsLoaded,
  selectZonesMapsLoading,
  selectFieldFeatureRecord,
  selectBoundaryUrl,
  selectIsFieldAndSatellitesLoaded,
  selectVectorAnalysisMap,
  selectName,
  selectField,
  selectSatelliteImages,
  selectVectorAnalysisMaps,
  selectEquationMaps,
  selectSoilDatasets,
  selectYieldDatasets,
  selectAsAppliedDatasets,
  selectTopographyMaps,
  selectIsFieldVamapsDatasetsPinsGroupsLoaded,
  selectIsSatelliteImagesLoaded,
  selectIsCategoryLoaded,
  selectCategoryLoading,
  selectFullyLoaded,
  selectThreeDimensionalMap,
  selectIsDatasetsSatellitesLoaded,
  selectFieldFeatureEdit,
  selectJDField,
  selectIsSomethingLoading,
} from './fieldSelectors';
import {
  selectSatelliteImages as selectSubscriptionSatelliteImages,
  selectSoilDatasets as selectSubscriptionSoilDatasets,
  selectYieldDatasets as selectSubscriptionYieldDatasets,
  selectAsAppliedDatasets as selectSubscriptionAsAppliedDatasets,
  selectTopographyMaps as selectSubscriptionTopographyMaps,
  selectVectorAnalysisMaps as selectSubscriptionVectorAnalysisMaps,
  selectEquationMaps as selectSubscriptionEquationMaps,
} from '../subscription/subscriptionSelectors';
import {
  fetchAllAssets as fetchAllAssetsAPI,
  fetchFieldVamaps as fetchFieldVamapsAPI,
  fetchVamapAttributesJson as fetchVamapAttributesJsonAPI,
  fetchBoundary,
  fetchFieldWithSatellites,
  deleteAsset as deleteAssetAPI,
  renameAsset as renameAssetAPI,
  fetchFieldData,
  fetchAssetsGroup as fetchAssetsGroupAPI,
  fetchFieldVamapPins as fetchFieldVamapPinsAPI,
  fetchVamapAssets as fetchVamapAssetsAPI,
  fetchFieldDatasets as fetchFieldDatasetsAPI,
  fetchAssetFeatures,
  saveBoundary,
  orderPlanetImage as orderPlanetImageAPI,
  fetchVamapGeojson as fetchVamapGeojsonAPI,
  generateReports,
  fetchReport,
} from './fieldAPI';
import {
  fetchAllSatelliteImages,
  fetchRequiredSatelliteImages,
  fetchSatelliteImagesGeoMaps as fetchSatelliteImagesGeoMapsAPI,
} from '../satelliteImages/satelliteImagesAPI';
import {
  errorNotify,
  warningNotify,
  successNotify,
  infoNotify,
} from '../notifications/helpers/functions/notify';
import { updateData as updateUserData } from '../user/userSlice';
import {
  finalizeEquationMapGeneration,
  updateEquationMap,
  updateVectorAnalysisMap,
} from '../createAnalysis/createAnalysisSlice';
import { saveVamap } from '../ui/zonesMap/zonesMapActions';
import { save as save3dMap } from '../ui/createThreeDMap/createThreeDMapSlice';
import { withFieldUuid } from './mixins/withFieldUuid';
import { isFeatureTypesCorrect, prepareBoundaryGeojson } from './helpers/functions/features';
import { isInvalid, isSameField } from './helpers/functions/field';
import { getDebouncer } from '../../helpers/functions/utils/debouncer';
import { selectApiKey, selectAreaUnit } from '../user/userSelectors';
import { getUserDataFetcher } from '../ui/applicationShell/applicationShellSlice';
import { createPinsGroup } from '../pins/helpers/functions/pinsGroup';
import { comparator } from '../../helpers/functions/entities/satelliteImage';
import { prepareZonesMapJson } from '../../helpers/analysis';
import { processSubscriptionActions } from '../subscription/helpers/functions/subscription';
import {
  ASSET_GROUP_TYPE_TO_TREE_NODE_ENTITY,
  AssetGroupType,
  AssetLoadStatus,
  AssetType,
} from '../../helpers/constants/entities/asset';
import { RemoteAssetStatus } from './helpers/constants/remoteAsset';
import { openInNewTab } from '../../helpers/navigation';
import { AreaUnit } from '../user/helpers/constants/user';
import { GeneratePdfReportArg, TransformedFieldAssetsResponse } from './types/api';
import { TransformedField } from './types/field';
import {
  AppDispatch,
  AppGetState,
  AppThunk,
} from '../../app/store/helpers/types';
import { createAppAsyncThunk } from '../../app/store/helpers/functions';
import { LoadStatus } from '../../helpers/constants/utils/loadStatus';
import { TransformedVectorAnalysisMap } from '../../helpers/types/vectorAnalysisMap';
import { GeoFormat } from '../../helpers/constants/api';
import { FIELD_STATUS_MESSAGES_TO_I18N_KEYS_MAP } from './helpers/constants/field';
import { ParsedEvent } from '../subscription/types/event';
import { PlatformEventAction } from '../subscription/helpers/constants/action';
import type { Action } from '../subscription/types/action';
import { getPdfReportSuccessActions } from '../notifications/helpers/functions/actions';
import type { GeoMapType, TransformedSatelliteImage } from '../satelliteImages/types/satelliteImage';
import {
  TransformedAsAppliedDataset,
  TransformedSoilDataset,
  TransformedTopographyMap,
  TransformedYieldDataset,
} from '../../helpers/types/dataset';
import { TransformedEquationMap } from '../../helpers/types/equationMap';
import { TransformedThreeDimensionalMap } from '../../helpers/types/threeDimensionalMap';
import { Comment, Pin, PinsGroup } from '../pins/types';
import { getJohnDeereFieldsIds } from '../jdFields/jdFieldsAPI';
import { captureException, CustomError } from '../../helpers/functions/utils/errorHandling';
import {
  getGeoMapTypeValue,
  getGeoMapLoadStatusKey,
} from '../satelliteImages/helpers/functions/satelliteImages';
import { GeoMapTypeOption } from '../satelliteImages/helpers/constants/geoMapType';
import { fieldsAPI } from '../fields/fieldsAPI';

interface FieldState {
  notFound: boolean;
  noMonitoring: boolean;
  field: TransformedField | null;
  jdField: { orgId: string } | null | false; // false means that field is not synchronized with John Deere
  feature: {
    saving: boolean;
    edit: FeatureCollection<MultiPolygon> | null;
    record: FeatureCollection<MultiPolygon> | null;
  };
  loading: {
    all: LoadStatus;
    attributes: LoadStatus,
    [AssetGroupType.satelliteImages]: LoadStatus;
    [AssetGroupType.vectorAnalysisMaps]: LoadStatus;
    [AssetGroupType.soilDatasets]: LoadStatus;
    [AssetGroupType.yieldDatasets]: LoadStatus;
    [AssetGroupType.asAppliedDatasets]: LoadStatus;
    [AssetGroupType.topographyMaps]: LoadStatus;
    [AssetGroupType.pinsGroups]: LoadStatus;
    [AssetGroupType.equationMaps]: LoadStatus;
    [AssetGroupType.threeDimensionalMaps]: LoadStatus;
  };
  fullyLoaded: {
    metadata: boolean;
    [AssetGroupType.satelliteImages]: boolean;
    [AssetGroupType.vectorAnalysisMaps]: boolean;
    [AssetGroupType.soilDatasets]: boolean;
    [AssetGroupType.yieldDatasets]: boolean;
    [AssetGroupType.asAppliedDatasets]: boolean;
    [AssetGroupType.topographyMaps]: boolean;
    [AssetGroupType.pinsGroups]: boolean;
    [AssetGroupType.equationMaps]: boolean;
    [AssetGroupType.threeDimensionalMaps]: boolean;
  };
}

export const initialState: FieldState = {
  notFound: false,
  noMonitoring: false,
  field: null,
  jdField: null,
  feature: {
    saving: false,
    edit: null,
    record: null,
  },
  loading: {
    all: LoadStatus.idle,
    [AssetGroupType.satelliteImages]: LoadStatus.idle,
    [AssetGroupType.vectorAnalysisMaps]: LoadStatus.idle,
    [AssetGroupType.soilDatasets]: LoadStatus.idle,
    [AssetGroupType.yieldDatasets]: LoadStatus.idle,
    [AssetGroupType.asAppliedDatasets]: LoadStatus.idle,
    [AssetGroupType.topographyMaps]: LoadStatus.idle,
    [AssetGroupType.pinsGroups]: LoadStatus.idle,
    [AssetGroupType.equationMaps]: LoadStatus.idle,
    [AssetGroupType.threeDimensionalMaps]: LoadStatus.idle,
    attributes: LoadStatus.idle,
  },
  fullyLoaded: {
    metadata: false,
    [AssetGroupType.satelliteImages]: false,
    [AssetGroupType.vectorAnalysisMaps]: false,
    [AssetGroupType.soilDatasets]: false,
    [AssetGroupType.yieldDatasets]: false,
    [AssetGroupType.asAppliedDatasets]: false,
    [AssetGroupType.topographyMaps]: false,
    [AssetGroupType.pinsGroups]: false,
    [AssetGroupType.equationMaps]: false,
    [AssetGroupType.threeDimensionalMaps]: false,
  },
};

const fetchAssetsSatellites = async (
  {
    farmUuid,
    fieldUuid,
    requiredSatImages,
    fetcher,
  }: {
    farmUuid: string;
    fieldUuid: string;
    requiredSatImages?: string[];
    fetcher: ({ farmUuid, fieldUuid, areaUnit }: {
      farmUuid: string,
      fieldUuid: string,
      areaUnit: AreaUnit,
    }) => Promise<TransformedFieldAssetsResponse>,
  },
  { dispatch, getState }: {
    getState: AppGetState,
    dispatch: AppDispatch,
  },
): Promise<TransformedField | null> => {
  let result: TransformedField | null;

  try {
    const fieldAssetsResponse = await fetcher({
      farmUuid,
      fieldUuid,
      areaUnit: selectAreaUnit(getState()),
    });

    result = fieldAssetsResponse?.field || null;

    if (fieldAssetsResponse?.noMonitoring) {
      dispatch(setNoMonitoring());
    } else {
      dispatch(fetchSatellites({
        farmUuid,
        fieldUuid,
        requiredSatImages,
      }));
    }
  } catch (error) {
    errorNotify({
      error: new CustomError('[Field] Unable to request assets data.', {
        cause: error,
      }),
      dispatch,
    });

    throw error;
  }

  return result;
};

export const fetchAllAssets = createAppAsyncThunk(
  'field/fetchAllAssets',
  async (
    {
      farmUuid,
      fieldUuid,
      requiredSatImages,
    }: {
      farmUuid: string,
      fieldUuid: string,
      requiredSatImages?: string[],
    },
    thunkAPI,
  ) => {
    return fetchAssetsSatellites({
      farmUuid,
      fieldUuid,
      requiredSatImages,
      fetcher: fetchAllAssetsAPI,
    }, thunkAPI);
  },
  {
    condition: ({
      fieldUuid,
      requiredSatImages = [],
    }, { getState }) => {
      const state = getState();
      const satImages = selectSatelliteImages(state);
      const sameField = isSameField<TransformedField>(selectField(state), {
        _type: AssetType.field,
        uuid: fieldUuid,
      });
      const satImagesUuids = new Set(satImages.map(({ uuid }) => uuid));
      const hasRequiredImages = requiredSatImages.every((uuid) => {
        return satImagesUuids.has(uuid);
      });
      let result: boolean;

      if (sameField) {
        if (!hasRequiredImages) {
          result = true;
        } else {
          result = !(
            selectAllLoading(state)
            || (
              selectIsFieldVamapsDatasetsPinsGroupsLoaded(state)
              && (
                selectSatelliteLoading(state)
                || selectIsSatelliteImagesLoaded(state)
              )
            )
          );
        }
      } else {
        result = true;
      }

      return result;
    },
  },
);

export const fetchDatasets = createAppAsyncThunk(
  'field/fetchDatasets',
  async ({
    farmUuid,
    fieldUuid,
  }: {
    farmUuid: string,
    fieldUuid: string,
  }, { getState }) => {
    const state = getState();
    const areaUnit = selectAreaUnit(state);
    const fieldDatasetsResponse = await fetchFieldDatasetsAPI({
      farmUuid,
      fieldUuid,
      areaUnit,
    });

    return fieldDatasetsResponse?.field;
  },
  {
    condition: (payload, { getState }) => {
      const state = getState();
      let result: boolean;

      if (isSameField(selectField(state), {
        _type: AssetType.field,
        uuid: payload.fieldUuid,
      })
        && (selectIsSomethingLoading(state) || selectIsDatasetsSatellitesLoaded(state))
      ) {
        result = false;
      } else {
        result = true;
      }

      return result;
    },
  },
);

export const fetchDatasetsSatellites = createAppAsyncThunk(
  'field/fetchDatasetsSatellites',
  async ({
    farmUuid,
    fieldUuid,
  }: {
    farmUuid: string,
    fieldUuid: string,
  }, thunkAPI) => {
    return fetchAssetsSatellites({
      farmUuid,
      fieldUuid,
      fetcher: fetchFieldDatasetsAPI,
    }, thunkAPI);
  },
  {
    condition: (payload, { getState }) => {
      const state = getState();
      let result: boolean;

      if (isSameField(selectField(state), {
        _type: AssetType.field,
        uuid: payload.fieldUuid,
      })
        && (selectAllLoading(state) || selectIsDatasetsSatellitesLoaded(state))
      ) {
        result = false;
      } else {
        result = true;
      }

      return result;
    },
  },
);

export const fetchFieldVamaps = createAppAsyncThunk(
  'field/fetchFieldVamaps',
  async ({
    farmUuid,
    fieldUuid,
  }: {
    farmUuid: string,
    fieldUuid: string,
  }, { getState, dispatch }) => {
    await getUserDataFetcher();
    const areaUnit = selectAreaUnit(getState());

    try {
      return await fetchFieldVamapsAPI(areaUnit, farmUuid, fieldUuid);
    } catch (error) {
      errorNotify({
        error: new CustomError('[Field] Unable to fetch field with vamaps.', {
          cause: error,
        }),
        dispatch,
      });

      throw error;
    }
  },
  {
    condition: (payload, { getState }) => {
      const state = getState();
      let result: boolean;

      if (
        isSameField(selectField(state), {
          _type: AssetType.field,
          uuid: payload.fieldUuid,
        })
        && (
          selectAllLoading(state)
          || selectZonesMapsLoading(state)
          || selectIsFieldAndZonesMapsLoaded(state)
        )
      ) {
        result = false;
      } else {
        result = true;
      }

      return result;
    },
  },
);

export const fetchVamapGeojson = createAppAsyncThunk(
  'field/fetchVamapGeojson',
  async ({ uuid }: {
    uuid: string,
  }, { getState, dispatch }) => {
    await getUserDataFetcher();

    const state = getState();
    const areaUnit = selectAreaUnit(state);
    const fieldUuid = selectFieldUuid(state);
    const farmUuid = selectFarmUuid(state);

    try {
      if (!fieldUuid || !farmUuid) {
        throw new CustomError('fieldUuid or farmUuid is empty.');
      }

      return await fetchVamapGeojsonAPI({
        farmUuid,
        fieldUuid,
        uuid,
        areaUnit,
      });
    } catch (error) {
      errorNotify({
        error: new CustomError('[Field] Unable to fetch vamap geojson.', {
          cause: error,
        }),
        dispatch,
      });

      throw error;
    }
  },
  {
    condition: ({ uuid }, { getState }) => {
      const state = getState();
      const vamap = selectVectorAnalysisMap(state, uuid);
      let result: boolean;

      if (!vamap || vamap.zonesMapGeojson) {
        result = false;
      } else {
        result = true;
      }

      return result;
    },
  },
);

export const fetchVamapAttributesJson = createAppAsyncThunk(
  'field/fetchVamapAttributesJson',
  async ({ uuid }: {
    uuid: string,
  }, { getState, dispatch }) => {
    await getUserDataFetcher();

    const state = getState();
    const areaUnit = selectAreaUnit(state);
    const fieldUuid = selectFieldUuid(state);
    const farmUuid = selectFarmUuid(state);

    try {
      if (!fieldUuid || !farmUuid) {
        throw new CustomError('fieldUuid or farmUuid is empty.');
      }

      return await fetchVamapAttributesJsonAPI({
        farmUuid,
        fieldUuid,
        uuid,
        areaUnit,
      });
    } catch (error) {
      errorNotify({
        error: new CustomError('[Field] Unable to fetch vamap attributes json.', {
          cause: error,
        }),
        dispatch,
      });

      throw error;
    }
  },
  {
    condition: ({ uuid }, { getState }) => {
      const state = getState();
      const vamap = selectVectorAnalysisMap(state, uuid);
      let result: boolean;

      if (!vamap || vamap.attributes) {
        result = false;
      } else {
        result = true;
      }

      return result;
    },
  },
);

export const fetchFieldVamapPins = createAppAsyncThunk(
  'field/fetchFieldVamapPins',
  async ({
    farmUuid,
    fieldUuid,
    uuid,
  }: {
    farmUuid: string,
    fieldUuid: string,
    uuid: string,
  }, { getState }) => {
    await getUserDataFetcher();

    return fetchFieldVamapPinsAPI({
      farmUuid,
      fieldUuid,
      uuid,
      areaUnit: selectAreaUnit(getState()),
    });
  },
);

export const fetchVamapAssets = createAppAsyncThunk(
  'field/fetchVamapAssets',
  ({
    farmUuid,
    fieldUuid,
    vamap,
  }: {
    farmUuid: string,
    fieldUuid: string,
    vamap: TransformedVectorAnalysisMap,
  }) => {
    return fetchVamapAssetsAPI({
      farmUuid,
      fieldUuid,
      vamap,
    });
  },
  {
    condition: ({ vamap }, { getState }) => {
      const state = getState();
      let result: boolean;

      if (isRequiredDataLoaded(vamap, selectField(state), selectFullyLoaded(state))) {
        result = false;
      } else {
        result = true;
      }

      return result;
    },
  },
);

export const fetchSatelliteImagesGeoMaps = createAppAsyncThunk(
  'field/fetchSatelliteImagesGeoMaps',
  ({
    uuids,
    geoMapTypes,
    isRawType,
  }: {
    uuids: string[],
    geoMapTypes: GeoMapTypeOption[],
    isRawType?: boolean,
  }, { getState, dispatch }) => {
    const state = getState();
    const fieldUuid = selectFieldUuid(state);
    const farmUuid = selectFarmUuid(state);

    try {
      if (!fieldUuid || !farmUuid) {
        throw new CustomError('fieldUuid or farmUuid is empty.');
      }

      const types = geoMapTypes
        .map((geoMapType) => getGeoMapTypeValue({ isRawType, geoMapType }))
        .filter((type): type is GeoMapType => !!type);

      return fetchSatelliteImagesGeoMapsAPI({
        farmUuid,
        fieldUuid,
        uuids,
        types,
      });
    } catch (error) {
      const geoMapTypeString = geoMapTypes.join(', ');

      errorNotify({
        error: new CustomError(`[Field] Unable to fetch ${geoMapTypeString} sat image geomaps.`, {
          cause: error,
        }),
        dispatch,
      });

      throw error;
    }
  },
  {
    condition: ({ uuids, isRawType, geoMapTypes }, { getState }) => {
      const state = getState();
      const images = selectSatelliteImages(state);
      const uuidsSet = new Set(uuids);
      const loadStatusKeys = geoMapTypes.map((geoMapType) => {
        return getGeoMapLoadStatusKey({ isRawType, geoMapType });
      });

      return images.some((image) => {
        if (!uuidsSet.has(image.uuid)) {
          return false;
        }

        return loadStatusKeys.some((loadStatusKey) => {
          const loadStatus = loadStatusKey ? image[loadStatusKey] : null;

          return !isAssetRequested(loadStatus);
        });
      });
    },
  },
);

export const fetchBoundaryFeature = withFieldUuid(createAppAsyncThunk(
  'field/fetchBoundaryFeature',
  async (
    _p: { fieldUuid: string },
    { dispatch, getState },
  ) => {
    await getUserDataFetcher();

    const state = getState();
    const apiKey = selectApiKey(state);
    const boundaryUrl = selectBoundaryUrl(state);

    try {
      if (!boundaryUrl) {
        throw new CustomError('[Field] boundaryUrl is empty.');
      }

      return await fetchBoundary(boundaryUrl, apiKey);
    } catch (error) {
      errorNotify({
        error: new CustomError('[Field] Unable to fetch boundary feature.', {
          cause: error,
        }),
        dispatch,
      });

      throw error;
    }
  },
  {
    condition: (_p, { getState }) => {
      const state = getState();
      const boundaryUrl = selectBoundaryUrl(state);
      const featureRecord = selectFieldFeatureRecord(state);

      let result: boolean;

      if (!boundaryUrl || featureRecord) {
        result = false;
      } else {
        result = true;
      }

      return result;
    },
  },
));

export const fetchFieldWithSatelliteImages = createAppAsyncThunk(
  'field/fetchFieldWithSatelliteImages',
  async ({
    farmUuid,
    fieldUuid,
    requiredSatImages = [],
  }: {
    farmUuid: string,
    fieldUuid: string,
    requiredSatImages?: string[],
  }, { getState }) => {
    const state = getState();
    const areaUnit = selectAreaUnit(state);
    const field = await fetchFieldWithSatellites({
      farmUuid,
      fieldUuid,
      areaUnit,
    });
    const fullSatImages = await fetchRequiredSatelliteImages({
      farmUuid,
      fieldUuid,
      fetched: field.satelliteImages || [],
      required: requiredSatImages,
    });

    return {
      ...field,
      satelliteImages: fullSatImages,
    };
  },
  {
    condition: ({
      fieldUuid,
      requiredSatImages = [],
    }, { getState }) => {
      const state = getState();
      const satImages = selectSatelliteImages(state);
      const sameField = isSameField<TransformedField>(selectField(state), {
        _type: AssetType.field,
        uuid: fieldUuid,
      });
      const satImagesUuids = new Set(satImages.map(({ uuid }) => uuid));
      const hasRequiredImages = requiredSatImages.every((uuid) => {
        return satImagesUuids.has(uuid);
      });
      let result: boolean;

      if (sameField) {
        if (!hasRequiredImages) {
          result = true;
        } else {
          result = !(
            selectAllLoading(state)
            || selectSatelliteLoading(state)
            || selectIsFieldAndSatellitesLoaded(state)
          );
        }
      } else {
        result = true;
      }

      return result;
    },
  },
);

export const fetchAssetsGroup = createAppAsyncThunk(
  'field/fetchAssetsGroup',
  ({
    farmUuid,
    fieldUuid,
    assetsGroupType,
  }: {
    farmUuid: string,
    fieldUuid: string,
    assetsGroupType: AssetGroupType,
  }, { getState }) => {
    const areaUnit = selectAreaUnit(getState());

    return fetchAssetsGroupAPI({
      farmUuid,
      fieldUuid,
      assetsGroupType,
      areaUnit,
    });
  },
  {
    condition: ({
      farmUuid,
      fieldUuid,
      assetsGroupType,
    }, { getState }) => {
      const state = getState();
      let result: boolean;

      if (
        farmUuid === selectFarmUuid(state)
        && fieldUuid === selectFieldUuid(state)
        && (
          selectCategoryLoading(state, assetsGroupType)
          || selectIsCategoryLoaded(state, assetsGroupType)
        )
      ) {
        result = false;
      } else {
        result = true;
      }

      return result;
    },
  },
);

export const fetchSatellites = createAppAsyncThunk(
  'field/fetchSatellites',
  async ({
    farmUuid,
    fieldUuid,
    requiredSatImages = [],
  }: {
    farmUuid: string,
    fieldUuid: string,
    requiredSatImages?: string[],
  }, { dispatch }) => {
    try {
      const fetchedSatImages = await fetchAllSatelliteImages(farmUuid, fieldUuid);

      return await fetchRequiredSatelliteImages({
        farmUuid,
        fieldUuid,
        fetched: fetchedSatImages,
        required: requiredSatImages,
      });
    } catch (error) {
      errorNotify({
        error: new CustomError('[Field] Unable to fetch satellite images.', {
          cause: error,
        }),
        dispatch,
      });

      throw error;
    }
  },
  {
    condition: ({
      fieldUuid,
      requiredSatImages = [],
    }, { getState }) => {
      const state = getState();
      const satImages = selectSatelliteImages(state);
      const sameField = isSameField<TransformedField>(selectField(state), {
        _type: AssetType.field,
        uuid: fieldUuid,
      });
      const satImagesUuids = new Set(satImages.map(({ uuid }) => uuid));
      const hasRequiredImages = requiredSatImages.every((uuid) => {
        return satImagesUuids.has(uuid);
      });
      let result: boolean;

      if (sameField) {
        // always fetch sat images if required are passed
        // FIXME: loading/loaded state is incorrect in case of 2 concurrent requests
        if (requiredSatImages.length !== 0) {
          result = !hasRequiredImages;
        } else {
          result = !selectSatelliteLoading(state);
        }
      } else {
        result = true;
      }

      return result;
    },
  },
);

export const fetch3dMapShapePoints = createAppAsyncThunk(
  'field/fetch3dMapShapePoints',
  async ({ map3dUuid }: {
    map3dUuid: string,
  }, { dispatch, getState, rejectWithValue }) => {
    const state = getState();
    const farmUuid = selectFarmUuid(state);
    const fieldUuid = selectFieldUuid(state);
    const { dataSourceLayer } = selectThreeDimensionalMap(state, map3dUuid) || {};

    if (!dataSourceLayer || !farmUuid || !fieldUuid) {
      return Promise.resolve();
    }

    let assetType: AssetType;
    let uuid: string;

    if (dataSourceLayer.soilDataset && dataSourceLayer.soilAttribute) {
      assetType = AssetType.soilDataset;
      uuid = dataSourceLayer.soilDataset.uuid;
    } else if (dataSourceLayer.yieldDataset && dataSourceLayer.yieldAttribute) {
      assetType = AssetType.yieldDataset;
      uuid = dataSourceLayer.yieldDataset.uuid;
    } else if (dataSourceLayer.asAppliedDataset && dataSourceLayer.asAppliedAttribute) {
      assetType = AssetType.asAppliedDataset;
      uuid = dataSourceLayer.asAppliedDataset.uuid;
    } else if (dataSourceLayer.topographyMap && dataSourceLayer.topographyAttribute) {
      assetType = AssetType.topographyMap;
      uuid = dataSourceLayer.topographyMap.uuid;
    } else {
      const error = new CustomError('[Field] Invalid input for fetchAssetFeatures.');
      errorNotify({
        error,
        dispatch,
      });

      throw error;
    }

    try {
      const response = await fetchAssetFeatures({
        assetType,
        uuid,
        farmUuid,
        fieldUuid,
        format: GeoFormat.geojson,
      });

      const points = await response.json();

      return {
        points,
        uuid: map3dUuid,
      };
    } catch (error) {
      if (error === RemoteAssetStatus.invalid) {
        captureException({
          error: new CustomError('[Field] Unable to fetch 3D Map shape points', {
            cause: error,
          }),
        });
      }

      return rejectWithValue(error);
    }
  },
  {
    condition: ({ map3dUuid }, { getState }) => {
      const state = getState();
      const map3d = selectThreeDimensionalMap(state, map3dUuid);
      let result: boolean;

      if (map3d?.pointsStored) {
        result = false;
      } else {
        result = true;
      }

      return result;
    },
  },
);

export const fetchSynchronizedJohnDeereField = createAppAsyncThunk(
  'field/fetchSynchronizedJohnDeereField',
  async (
    {
      fieldUuid,
    }: {
      fieldUuid: string;
    },
    {
      dispatch,
    }: {
      dispatch: AppDispatch,
    },
  ) => {
    try {
      // @ts-expect-error
      const { fields } = await getJohnDeereFieldsIds({ fieldUuid });

      return fields[0];
    } catch (error) {
      errorNotify({
        error,
        dispatch,
      });
    }
  },
  {
    condition: (_, { getState }) => {
      const state = getState();
      const synchronizedField = selectJDField(state);
      return synchronizedField === null;
    },
  },
);

export const deleteAsset = createAppAsyncThunk(
  'field/deleteAsset',
  (payload: string, { getState, dispatch }) => {
    const state = getState();
    const asset = getAssetByUuid(payload, selectField(state));
    const fieldUuid = selectFieldUuid(state);

    if (fieldUuid && asset) {
      return deleteAssetAPI(asset, fieldUuid);
    }
    const error = new CustomError('[Field] Invalid input for deleteAssetAPI.');
    errorNotify({
      error,
      dispatch,
    });

    throw error;
  },
);

export const renameAsset = createAppAsyncThunk(
  'field/renameAsset',
  ({ name, uuid }: {
    name: string,
    uuid: string,
  }, { getState, dispatch }) => {
    const state = getState();
    const asset = getAssetByUuid(uuid, selectField(state));
    const fieldUuid = selectFieldUuid(state);

    if (fieldUuid && asset) {
      return renameAssetAPI(asset, name, fieldUuid);
    }
    const error = new CustomError('[Field] Invalid input for renameAssetAPI.');
    errorNotify({
      error,
      dispatch,
    });

    throw error;
  },
);

export const saveBoundaryFeature = withFieldUuid(createAppAsyncThunk(
  'field/saveBoundaryFeature',
  async (_p: { fieldUuid: string }, { dispatch, getState }) => {
    const state = getState();
    const farmUuid = selectFarmUuid(state);
    const fieldUuid = selectFieldUuid(state);
    const fieldName = selectName(state);
    const featureCollection = selectFieldFeatureEdit(state);

    if (featureCollection?.features && fieldUuid && farmUuid && fieldName) {
      try {
        const geojson = prepareBoundaryGeojson(featureCollection.features);
        const registerField = await saveBoundary({
          uuid: fieldUuid,
          farmUuid,
          name: fieldName,
          geojson,
        });

        if (!registerField) {
          throw new CustomError('[Field] saveBoundaryFeature: registerField is empty.');
        }

        if (isInvalid(registerField)) {
          const i18nKey = FIELD_STATUS_MESSAGES_TO_I18N_KEYS_MAP[registerField.statusMessage];

          if (i18nKey) {
            const errorMessage = i18n.t(i18nKey);
            warningNotify({
              message: errorMessage,
            });
          }
        } else {
          successNotify({
            message: i18n.t('general.notifications.field-saved'),
          });
        }
      } catch (error) {
        errorNotify({
          error: new CustomError('[Field] Unable to save boundary feature.', {
            cause: error,
          }),
          dispatch,
        });
      }
    }
  },
  {
    condition: (_p, { getState }) => {
      const state = getState();
      const featureCollection = selectFieldFeatureEdit(state);

      return isFeatureTypesCorrect(featureCollection?.features);
    },
  },
));

export const orderPlanetImage = createAppAsyncThunk(
  'field/orderPlanetImage',
  async ({
    fieldUuid,
    satelliteImageUuid,
  }: {
    fieldUuid: string,
    satelliteImageUuid: string,
  }, { dispatch }) => {
    const response = await orderPlanetImageAPI({
      fieldUuid,
      satelliteImageUuid,
    });
    let hasErrors = true;

    if (!response || 'errors' in response) {
      errorNotify({
        error: new CustomError('[Field] orderPlanetImage: Unable to order Planet image.', {
          cause: response?.errors,
        }),
        dispatch,
      });
    } else if ('statusCode' in response && response.statusCode !== 202) {
      errorNotify({
        error: new CustomError('[Field] orderPlanetImage: Incorrect response status code.', {
          cause: response,
        }),
        dispatch,
      });
    } else {
      hasErrors = false;
      successNotify({
        message: i18n.t('field.notifications.planet-image-ordered'),
      });
    }

    return hasErrors;
  },
);

export const generatePdfReport = createAppAsyncThunk(
  'field/generatePdfReport',
  async ({
    exportAssetsData,
  }: GeneratePdfReportArg, { getState, dispatch }) => {
    const state = getState();
    const farmUuid = selectFarmUuid(state);
    const fieldUuid = selectFieldUuid(state);
    const assets = exportAssetsData.map((asset) => {
      return {
        type: ASSET_GROUP_TYPE_TO_TREE_NODE_ENTITY[asset.assetGroupType],
        uuid: asset.uuid,
        attributes: asset.attributes?.reduce((acc: string[], { attribute }) => {
          return attribute ? [...acc, attribute] : acc;
        }, []),
      };
    });

    if (!farmUuid || !fieldUuid) {
      throw new CustomError('[Field] generatePdfReport: input is invalid.');
    }

    infoNotify({
      message: i18n.t('field.notifications.pdf-report-in-progress'),
      key: fieldUuid,
    });

    try {
      return await generateReports([{
        farmUuid,
        fieldUuid,
        format: 'PDF',
        assets,
      }]);
    } catch (error) {
      errorNotify({
        error: new CustomError('[Field] Unable to generate PDF report.', {
          cause: error,
        }),
        dispatch,
      });

      throw error;
    }
  },
);

const satelliteImagesDebouncer = getDebouncer(1000);
const analysisMapsDebouncer = getDebouncer(3000);

export const subscription = (parsedEvent: ParsedEvent): AppThunk => async (dispatch, getState) => {
  const {
    action,
    pathLength,
    farmUuid,
    fieldUuid,
    satelliteImageUuid,
    vectorAnalysisMapUuid,
    equationMapUuid,
    soilDatasetUuid,
    yieldDatasetUuid,
    asAppliedDatasetUuid,
    elevationUuid,
    reportUuid,
  } = parsedEvent;
  const state = getState();
  const areaUnit = selectAreaUnit(state);
  const loading = selectAllLoading(state);

  if (farmUuid !== selectFarmUuid(state) || fieldUuid !== selectFieldUuid(state)) {
    return;
  }

  if (
    fieldUuid !== ''
      && pathLength === 2
      && (
        action === PlatformEventAction.insert
          || action === PlatformEventAction.modify
      )
  ) {
    try {
      const field = await fetchFieldData({
        farmUuid,
        fieldUuid,
        areaUnit,
      });

      dispatch(updateData(field));
    } catch (error) {
      errorNotify({
        error: new CustomError('[Field Subscription] Unable to fetch field data.', {
          cause: error,
        }),
        dispatch,
      });
    }
  } else if (satelliteImageUuid !== '' && pathLength === 3) {
    satelliteImagesDebouncer(() => {
      let updatedState = state;

      if (!loading) {
        dispatch(addSatelliteImage({
          action,
          farmUuid,
          fieldUuid,
          uuid: satelliteImageUuid,
        }));

        updatedState = getState();
      }

      const satelliteImagesActions = selectSubscriptionSatelliteImages(updatedState);
      const satelliteImages = selectSatelliteImages(updatedState);
      const fetcher = async (uuids: string[]) => {
        try {
          return await fetchAssetsGroupAPI({
            assetsGroupType: AssetGroupType.satelliteImages,
            farmUuid,
            fieldUuid,
            uuids,
          }) as TransformedSatelliteImage[];
        } catch (e) {
          // TODO: Implement retry mechanism in order not to lose data
          return [];
        }
      };
      const updater = (assets: TransformedSatelliteImage[]) => {
        dispatch(updateAssetsCategory({
          farmUuid,
          fieldUuid,
          category: AssetGroupType.satelliteImages,
          data: assets.sort(comparator),
        }));
      };
      const cleaner = (actions: Action[]) => {
        dispatch(deleteSatelliteImages(actions.map((a) => {
          return a.uuid;
        })));
      };

      processSubscriptionActions<TransformedSatelliteImage>(
        satelliteImagesActions,
        satelliteImages,
        fetcher,
        updater,
        cleaner,
      );
    });
  } else if (vectorAnalysisMapUuid !== '' && pathLength === 3) {
    analysisMapsDebouncer(() => {
      let updatedState = state;

      if (!loading) {
        dispatch(addVectorAnalysisMap({
          action,
          farmUuid,
          fieldUuid,
          uuid: vectorAnalysisMapUuid,
        }));

        updatedState = getState();
      }

      const vamapsActions = selectSubscriptionVectorAnalysisMaps(updatedState);
      const vamaps = selectVectorAnalysisMaps(updatedState);
      const fetcher = async (uuids: string[]) => {
        try {
          return await fetchAssetsGroupAPI({
            assetsGroupType: AssetGroupType.vectorAnalysisMaps,
            farmUuid,
            fieldUuid,
            uuids,
            areaUnit,
          }) as TransformedVectorAnalysisMap[];
        } catch (e) {
          const error = new CustomError('[Field] subscription: Unable to fetch vamaps.', {
            cause: e,
          });
          errorNotify({
            error,
            dispatch,
          });

          return [];
        }
      };
      const updater = (assets: TransformedVectorAnalysisMap[]) => {
        dispatch(updateAssetsCategory({
          farmUuid,
          fieldUuid,
          category: AssetGroupType.vectorAnalysisMaps,
          data: assets,
        }));
      };
      const cleaner = (actions: Action[]) => {
        dispatch(deleteVectorAnalysisMaps(actions.map((a) => {
          return a.uuid;
        })));
      };

      processSubscriptionActions(
        vamapsActions,
        vamaps,
        fetcher,
        updater,
        cleaner,
      );
    });
  } else if (equationMapUuid !== '' && pathLength === 3) {
    analysisMapsDebouncer(() => {
      let updatedState = state;

      if (!loading) {
        dispatch(addEquationMap({
          action,
          farmUuid,
          fieldUuid,
          uuid: equationMapUuid,
        }));

        updatedState = getState();
      }

      const equationMapsActions = selectSubscriptionEquationMaps(updatedState);
      const equationMaps = selectEquationMaps(updatedState);

      const fetcher = async (uuids: string[]) => {
        try {
          return await fetchAssetsGroupAPI({
            assetsGroupType: AssetGroupType.equationMaps,
            farmUuid,
            fieldUuid,
            uuids,
            areaUnit,
          }) as TransformedEquationMap[];
        } catch (e) {
          const error = new CustomError('[Field] subscription: Unable to fetch equation maps.', {
            cause: e,
          });
          errorNotify({
            error,
            dispatch,
          });

          return [];
        }
      };
      const updater = (assets: TransformedEquationMap[]) => {
        dispatch(updateAssetsCategory({
          farmUuid,
          fieldUuid,
          category: AssetGroupType.equationMaps,
          data: assets,
        }));
      };
      const cleaner = (actions: Action[]) => {
        dispatch(deleteEquationMaps(actions.map((a) => {
          return a.uuid;
        })));
      };

      processSubscriptionActions(
        equationMapsActions,
        equationMaps,
        fetcher,
        updater,
        cleaner,
      );
    });
  } else if (soilDatasetUuid !== '' && pathLength === 3) {
    let updatedState = state;

    if (!loading) {
      dispatch(addSoilDataset({
        action,
        farmUuid,
        fieldUuid,
        uuid: soilDatasetUuid,
      }));

      updatedState = getState();
    }

    const soilDatasetsActions = selectSubscriptionSoilDatasets(updatedState);
    const soilDatasets = selectSoilDatasets(updatedState);
    const fetcher = async (uuids: string[]) => {
      try {
        return await fetchAssetsGroupAPI({
          assetsGroupType: AssetGroupType.soilDatasets,
          farmUuid,
          fieldUuid,
          uuids,
        }) as TransformedSoilDataset[];
      } catch (e) {
        const error = new CustomError('[Field] subscription: Unable to fetch soil datasets.', {
          cause: e,
        });
        errorNotify({
          error,
          dispatch,
        });

        return [];
      }
    };
    const updater = (assets: TransformedSoilDataset[]) => {
      dispatch(updateAssetsCategory({
        farmUuid,
        fieldUuid,
        category: AssetGroupType.soilDatasets,
        data: assets,
      }));
    };
    const cleaner = (actions: Action[]) => {
      dispatch(deleteSoilDatasets(actions.map((a) => {
        return a.uuid;
      })));
    };

    processSubscriptionActions(
      soilDatasetsActions,
      soilDatasets,
      fetcher,
      updater,
      cleaner,
    );
  } else if (yieldDatasetUuid !== '' && pathLength === 3) {
    let updatedState = state;

    if (!loading) {
      dispatch(addYieldDataset({
        action,
        farmUuid,
        fieldUuid,
        uuid: yieldDatasetUuid,
      }));

      updatedState = getState();
    }

    const yieldDatasetsActions = selectSubscriptionYieldDatasets(updatedState);
    const yieldDatasets = selectYieldDatasets(updatedState);
    const fetcher = async (uuids: string[]) => {
      try {
        return await fetchAssetsGroupAPI({
          assetsGroupType: AssetGroupType.yieldDatasets,
          farmUuid,
          fieldUuid,
          uuids,
          areaUnit,
        }) as TransformedYieldDataset[];
      } catch (e) {
        const error = new CustomError('[Field] subscription: Unable to fetch yield datasets.', {
          cause: e,
        });
        errorNotify({
          error,
          dispatch,
        });

        return [];
      }
    };
    const updater = (assets: TransformedYieldDataset[]) => {
      dispatch(updateAssetsCategory({
        farmUuid,
        fieldUuid,
        category: AssetGroupType.yieldDatasets,
        data: assets,
      }));
    };
    const cleaner = (actions: Action[]) => {
      dispatch(deleteYieldDatasets(actions.map((a) => {
        return a.uuid;
      })));
    };

    processSubscriptionActions(
      yieldDatasetsActions,
      yieldDatasets,
      fetcher,
      updater,
      cleaner,
    );
  } else if (asAppliedDatasetUuid !== '' && pathLength === 3) {
    let updatedState = state;

    if (!loading) {
      dispatch(addAsAppliedDataset({
        action,
        farmUuid,
        fieldUuid,
        uuid: asAppliedDatasetUuid,
      }));

      updatedState = getState();
    }

    const asAppliedDatasetsActions = selectSubscriptionAsAppliedDatasets(updatedState);
    const asAppliedDatasets = selectAsAppliedDatasets(updatedState);
    const fetcher = async (uuids: string[]) => {
      try {
        return await fetchAssetsGroupAPI({
          assetsGroupType: AssetGroupType.asAppliedDatasets,
          farmUuid,
          fieldUuid,
          uuids,
          areaUnit,
        }) as TransformedAsAppliedDataset[];
      } catch (e) {
        const error = new CustomError('[Field] subscription: Unable to fetch as applied datasets.', {
          cause: e,
        });
        errorNotify({
          error,
          dispatch,
        });

        return [];
      }
    };
    const updater = (assets: TransformedAsAppliedDataset[]) => {
      dispatch(updateAssetsCategory({
        farmUuid,
        fieldUuid,
        category: AssetGroupType.asAppliedDatasets,
        data: assets,
      }));
    };
    const cleaner = (actions: Action[]) => {
      dispatch(deleteAsAppliedDatasets(actions.map((a) => {
        return a.uuid;
      })));
    };

    processSubscriptionActions(
      asAppliedDatasetsActions,
      asAppliedDatasets,
      fetcher,
      updater,
      cleaner,
    );
  } else if (elevationUuid !== '' && pathLength === 3) {
    let updatedState = state;

    if (!loading) {
      dispatch(addTopographyMap({
        action,
        farmUuid,
        fieldUuid,
        uuid: elevationUuid,
      }));

      updatedState = getState();
    }

    const topographyMapsActions = selectSubscriptionTopographyMaps(updatedState);
    const topographyMaps = selectTopographyMaps(updatedState);
    const fetcher = async (uuids: string[]) => {
      try {
        return await fetchAssetsGroupAPI({
          assetsGroupType: AssetGroupType.topographyMaps,
          farmUuid,
          fieldUuid,
          uuids,
        }) as TransformedTopographyMap[];
      } catch (e) {
        const error = new CustomError('[Field] subscription: Unable to fetch topography maps.', {
          cause: e,
        });
        errorNotify({
          error,
          dispatch,
        });

        return [];
      }
    };
    const updater = (assets: TransformedTopographyMap[]) => {
      dispatch(updateAssetsCategory({
        farmUuid,
        fieldUuid,
        category: AssetGroupType.topographyMaps,
        data: assets,
      }));
    };
    const cleaner = (actions: Action[]) => {
      dispatch(deleteTopographyMaps(actions.map((a) => {
        return a.uuid;
      })));
    };

    processSubscriptionActions(
      topographyMapsActions,
      topographyMaps,
      fetcher,
      updater,
      cleaner,
    );
  } else if (reportUuid !== '' && pathLength === 3) {
    try {
      const report = await fetchReport({
        farmUuid,
        fieldUuid,
        uuid: reportUuid,
      });

      if (!report) {
        throw new CustomError('[Field] subscription: report is empty.');
      }

      closeSnackbar(fieldUuid);
      const notificationId = successNotify({
        message: i18n.t('field.notifications.pdf-report-generated'),
        actions: getPdfReportSuccessActions(() => {
          openInNewTab(report.url);
          closeSnackbar(notificationId);
        }),
      });
    } catch (e) {
      const error = new CustomError('[Field] subscription: Unable to fetch PDF report.', {
        cause: e,
      });

      errorNotify({
        error,
        dispatch,
      });
    }
  }
};

export const fieldSlice = createSlice({
  name: 'field',
  initialState,
  reducers: {
    setNoMonitoring(state) {
      state.noMonitoring = true;
    },
    updateData(state, action: PayloadAction<TransformedField>) {
      if (isSameField(state.field, action.payload)) {
        state.field = {
          ...state.field,
          ...action.payload,
        };
      }
    },
    updateAssetsCategory(state, action: PayloadAction<{
      farmUuid: string;
      fieldUuid: string;
      category: AssetGroupType;
      data: TransformedSatelliteImage[]
      | TransformedSoilDataset[]
      | TransformedVectorAnalysisMap[]
      | TransformedEquationMap[]
      | TransformedThreeDimensionalMap[]
      | TransformedYieldDataset[]
      | TransformedAsAppliedDataset[]
      | TransformedTopographyMap[]
      | PinsGroup[]
    }>) {
      if (state.field && isSameField(state.field, {
        _type: AssetType.field,
        uuid: action.payload.fieldUuid,
      })) {
        // FIXME: find out how to infer types
        state.field[action.payload.category] = action.payload.data as any;
      }
    },
    addPin(state, action: PayloadAction<{
      fieldUuid: string;
      uuid?: string;
      pin: Pin,
    }>) {
      const { fieldUuid, pin, uuid: assetUuid } = action.payload;
      const uuid = assetUuid || fieldUuid;

      if (!state.field?.pinsGroups) {
        return;
      }

      let pinsGroup = state.field.pinsGroups.find(
        (pg) => pg.uuid === uuid && pg.fieldUuid === fieldUuid,
      );

      if (!pinsGroup) {
        const item = getAssetByUuid(uuid, state.field);

        if (item) {
          pinsGroup = createPinsGroup(item, fieldUuid);
          state.field.pinsGroups.push(pinsGroup);
        }
      }

      if (pinsGroup) {
        pinsGroup.pins.push(pin);
      }
    },
    updatePin(state, action: PayloadAction<{
      fieldUuid: string;
      uuid?: string;
      pin: Pin,
    }>) {
      const {
        fieldUuid,
        pin,
      } = action.payload;
      let { uuid } = action.payload;

      if (!uuid) {
        uuid = fieldUuid;
      }

      if (state.field?.pinsGroups) {
        state.field.pinsGroups.forEach((pinsGroup) => {
          if (pinsGroup.uuid !== uuid || pinsGroup.fieldUuid !== fieldUuid) {
            return;
          }

          pinsGroup.pins = pinsGroup.pins.map((p) => {
            if (p.uuid !== pin.uuid) {
              return p;
            }

            return pin;
          });
        });
      }
    },
    updatePins(state, action: PayloadAction<{
      fieldUuid: string;
      uuid?: string;
      pins: Pin[],
    }>) {
      const {
        fieldUuid,
        pins,
      } = action.payload;
      let { uuid } = action.payload;

      if (!uuid) {
        uuid = fieldUuid;
      }

      if (state.field?.pinsGroups) {
        state.field.pinsGroups.forEach((pinsGroup) => {
          if (
            pinsGroup.uuid !== uuid
          || pinsGroup.fieldUuid !== fieldUuid
          ) {
            return;
          }

          pinsGroup.pins = pinsGroup.pins.map((pin) => {
            const changedPin = pins.find((p) => p.uuid === pin.uuid);

            return changedPin || pin;
          });
        });
      }
    },
    deletePin(state, action: PayloadAction<{
      fieldUuid: string;
      uuid?: string;
      pinUuid: string,
    }>) {
      const {
        fieldUuid,
        pinUuid,
      } = action.payload;
      let { uuid } = action.payload;

      if (!uuid) {
        uuid = fieldUuid;
      }

      if (state.field?.pinsGroups) {
        const pinsGroupInd = state.field.pinsGroups.findIndex((pinsGroup) => {
          return pinsGroup.uuid === uuid && pinsGroup.fieldUuid === fieldUuid;
        });
        const pins = state.field.pinsGroups[pinsGroupInd].pins.filter((p) => p.uuid !== pinUuid);

        if (pins.length === 0) {
          state.field.pinsGroups.splice(pinsGroupInd, 1);
        } else {
          state.field.pinsGroups[pinsGroupInd].pins = pins;
        }
      }
    },
    addPinComment(state, action: PayloadAction<{
      itemUuid: string,
      savedComment: Comment,
    }>) {
      const { savedComment } = action.payload;
      const {
        fieldUuid,
        noteUuid,
      } = savedComment;

      if (state.field?.pinsGroups) {
        const itemUuid = action.payload.itemUuid || fieldUuid;

        const pinsGroup = state.field.pinsGroups.find((pg) => {
          return pg.uuid === itemUuid && pg.fieldUuid === fieldUuid;
        });

        const pins = pinsGroup?.pins || [];
        const editedPin = pins.find((pin) => pin.uuid === noteUuid);

        if (editedPin) {
          if (!Array.isArray(editedPin.comments)) {
            editedPin.comments = [];
          }
          editedPin.comments.push(savedComment);
        }
      }
    },
    setFeatureEdit(state, action) {
      state.feature.edit = action.payload;
    },
    resetFeatureEdit(state) {
      state.feature.edit = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchAllAssets.pending, (state, action) => {
        const field: TransformedField = {
          _type: AssetType.field,
          uuid: action.meta.arg.fieldUuid,
          farmUuid: action.meta.arg.farmUuid,
          name: state.field?.name,
          area: state.field?.area,
        };

        return {
          ...initialState,
          field,
          loading: {
            ...initialState.loading,
            all: LoadStatus.loading,
          },
        };
      })
      .addCase(fetchAllAssets.fulfilled, (state, action) => {
        const dataLoaded = !!action.payload;

        if (isSameField<TransformedField>(state.field, {
          _type: AssetType.field,
          uuid: action.meta.arg.fieldUuid,
        })) {
          if (dataLoaded) {
            state.notFound = false;
            state.loading.all = LoadStatus.idle;
            state.field = action.payload;
            state.fullyLoaded.metadata = true;
            state.fullyLoaded[AssetGroupType.vectorAnalysisMaps] = true;
            state.fullyLoaded[AssetGroupType.soilDatasets] = true;
            state.fullyLoaded[AssetGroupType.yieldDatasets] = true;
            state.fullyLoaded[AssetGroupType.asAppliedDatasets] = true;
            state.fullyLoaded[AssetGroupType.topographyMaps] = true;
            state.fullyLoaded[AssetGroupType.pinsGroups] = true;
            state.fullyLoaded[AssetGroupType.equationMaps] = true;
            state.fullyLoaded[AssetGroupType.threeDimensionalMaps] = true;
          } else {
            state.notFound = true;
            state.field = {
              _type: AssetType.field,
              uuid: action.meta.arg.fieldUuid,
            };
          }
        }
      })
      .addCase(fetchSatellites.pending, (state, action) => {
        const field: TransformedField = {
          _type: AssetType.field,
          uuid: action.meta.arg.fieldUuid,
          farmUuid: action.meta.arg.farmUuid,
        };
        let result: FieldState;

        if (isSameField(state.field, field)) {
          result = state;
        } else {
          result = {
            ...initialState,
            field,
          };
        }

        result = produce(result, (draft) => {
          draft.loading[AssetGroupType.satelliteImages] = LoadStatus.loading;
          draft.fullyLoaded[AssetGroupType.satelliteImages] = false;
        });

        return result;
      })
      .addCase(fetchSatellites.fulfilled, (state, action) => {
        if (state.field && isSameField<TransformedField>(state.field, {
          _type: AssetType.field,
          uuid: action.meta.arg.fieldUuid,
        })) {
          state.loading[AssetGroupType.satelliteImages] = LoadStatus.idle;
          state.fullyLoaded[AssetGroupType.satelliteImages] = true;
          state.field.satelliteImages = action.payload;
        }
      })
      .addCase(fetchDatasetsSatellites.pending, (state, action) => {
        let result: FieldState;
        const field: TransformedField = {
          _type: AssetType.field,
          uuid: action.meta.arg.fieldUuid,
          farmUuid: action.meta.arg.farmUuid,
        };

        if (isSameField<TransformedField>(state.field, field)) {
          result = state;
        } else {
          result = {
            ...initialState,
            field,
          };
        }

        result = produce(result, (draft) => {
          draft.loading[AssetGroupType.satelliteImages] = LoadStatus.loading;
          draft.loading[AssetGroupType.soilDatasets] = LoadStatus.loading;
          draft.loading[AssetGroupType.yieldDatasets] = LoadStatus.loading;
          draft.loading[AssetGroupType.asAppliedDatasets] = LoadStatus.loading;
          draft.loading[AssetGroupType.topographyMaps] = LoadStatus.loading;
          draft.fullyLoaded[AssetGroupType.satelliteImages] = false;
          draft.fullyLoaded[AssetGroupType.soilDatasets] = false;
          draft.fullyLoaded[AssetGroupType.yieldDatasets] = false;
          draft.fullyLoaded[AssetGroupType.asAppliedDatasets] = false;
          draft.fullyLoaded[AssetGroupType.topographyMaps] = false;
        });

        return result;
      })
      .addCase(fetchDatasetsSatellites.fulfilled, (state, action) => {
        if (state.field && isSameField<TransformedField>(state.field, {
          _type: AssetType.field,
          uuid: action.meta.arg.fieldUuid,
        })) {
          state.loading[AssetGroupType.satelliteImages] = LoadStatus.idle;
          state.loading[AssetGroupType.soilDatasets] = LoadStatus.idle;
          state.loading[AssetGroupType.yieldDatasets] = LoadStatus.idle;
          state.loading[AssetGroupType.asAppliedDatasets] = LoadStatus.idle;
          state.loading[AssetGroupType.topographyMaps] = LoadStatus.idle;
          state.fullyLoaded[AssetGroupType.satelliteImages] = true;
          state.fullyLoaded[AssetGroupType.soilDatasets] = true;
          state.fullyLoaded[AssetGroupType.yieldDatasets] = true;
          state.fullyLoaded[AssetGroupType.asAppliedDatasets] = true;
          state.fullyLoaded[AssetGroupType.topographyMaps] = true;
          state.field = {
            ...state.field,
            ...action.payload,
          };
        }
      })
      .addCase(fetchDatasets.pending, (state, action) => {
        let result: FieldState;
        const field: TransformedField = {
          _type: AssetType.field,
          uuid: action.meta.arg.fieldUuid,
          farmUuid: action.meta.arg.farmUuid,
        };

        if (isSameField<TransformedField>(state.field, field)) {
          result = state;
        } else {
          result = {
            ...initialState,
            field,
          };
        }

        result = produce(result, (draft) => {
          draft.loading[AssetGroupType.soilDatasets] = LoadStatus.loading;
          draft.loading[AssetGroupType.yieldDatasets] = LoadStatus.loading;
          draft.loading[AssetGroupType.asAppliedDatasets] = LoadStatus.loading;
          draft.loading[AssetGroupType.topographyMaps] = LoadStatus.loading;
          draft.fullyLoaded[AssetGroupType.soilDatasets] = false;
          draft.fullyLoaded[AssetGroupType.yieldDatasets] = false;
          draft.fullyLoaded[AssetGroupType.asAppliedDatasets] = false;
          draft.fullyLoaded[AssetGroupType.topographyMaps] = false;
        });

        return result;
      })
      .addCase(fetchDatasets.fulfilled, (state, action) => {
        if (state.field && isSameField<TransformedField>(state.field, {
          _type: AssetType.field,
          uuid: action.meta.arg.fieldUuid,
        })) {
          state.loading[AssetGroupType.soilDatasets] = LoadStatus.idle;
          state.loading[AssetGroupType.yieldDatasets] = LoadStatus.idle;
          state.loading[AssetGroupType.asAppliedDatasets] = LoadStatus.idle;
          state.loading[AssetGroupType.topographyMaps] = LoadStatus.idle;
          state.fullyLoaded[AssetGroupType.soilDatasets] = true;
          state.fullyLoaded[AssetGroupType.yieldDatasets] = true;
          state.fullyLoaded[AssetGroupType.asAppliedDatasets] = true;
          state.fullyLoaded[AssetGroupType.topographyMaps] = true;
          state.field = {
            ...state.field,
            ...action.payload,
          };
        }
      })
      .addCase(fetchFieldVamaps.pending, (state, action) => {
        let result: FieldState;
        const field: TransformedField = {
          _type: AssetType.field,
          uuid: action.meta.arg.fieldUuid,
          farmUuid: action.meta.arg.farmUuid,
        };

        if (isSameField<TransformedField>(state.field, field)) {
          result = state;
        } else {
          result = {
            ...initialState,
            field,
          };
        }

        result = produce(result, (draft) => {
          draft.loading[AssetGroupType.vectorAnalysisMaps] = LoadStatus.loading;
          draft.fullyLoaded[AssetGroupType.vectorAnalysisMaps] = false;
        });

        return result;
      })
      .addCase(fetchFieldVamaps.fulfilled, (state, action) => {
        if (state.field && isSameField<TransformedField>(state.field, {
          _type: AssetType.field,
          uuid: action.meta.arg.fieldUuid,
        })) {
          state.loading[AssetGroupType.vectorAnalysisMaps] = LoadStatus.idle;
          state.fullyLoaded.metadata = true;
          state.fullyLoaded[AssetGroupType.vectorAnalysisMaps] = true;
          state.field = {
            ...state.field,
            ...action.payload,
          };
        }
      })
      .addCase(fetchFieldWithSatelliteImages.pending, (state, action) => {
        let result: FieldState;
        const field: TransformedField = {
          _type: AssetType.field,
          uuid: action.meta.arg.fieldUuid,
          farmUuid: action.meta.arg.farmUuid,
        };

        if (isSameField<TransformedField>(state.field, field)) {
          result = state;
        } else {
          result = {
            ...initialState,
            field,
          };
        }

        result = produce(result, (draft) => {
          draft.loading[AssetGroupType.satelliteImages] = LoadStatus.loading;
          draft.fullyLoaded[AssetGroupType.satelliteImages] = false;
        });

        return result;
      })
      .addCase(fetchFieldWithSatelliteImages.fulfilled, (state, action) => {
        if (state.field && isSameField<TransformedField>(state.field, {
          _type: AssetType.field,
          uuid: action.meta.arg.fieldUuid,
        })) {
          state.loading[AssetGroupType.satelliteImages] = LoadStatus.idle;
          state.fullyLoaded[AssetGroupType.satelliteImages] = true;
          state.fullyLoaded.metadata = true;
          state.field = {
            ...state.field,
            ...action.payload,
          };
        }
      })
      .addCase(fetchVamapAttributesJson.pending, (state) => {
        state.loading.attributes = LoadStatus.loading;
      })
      .addCase(fetchVamapAttributesJson.fulfilled, (state, action) => {
        state.loading.attributes = LoadStatus.idle;
        const vamap = state.field?.vectorAnalysisMaps?.find(({ uuid }) => {
          return uuid === action.meta.arg.uuid;
        });

        if (vamap) {
          vamap.attributes = action.payload;
        }
      })
      .addCase(fetchVamapGeojson.fulfilled, (state, action) => {
        const vamap = (state.field?.vectorAnalysisMaps || []).find(({ uuid }) => {
          return uuid === action.meta.arg.uuid;
        });

        if (vamap) {
          vamap.zonesMapGeojson = action.payload;
        }
      })
      .addCase(fetchFieldVamapPins.fulfilled, (state, action) => {
        let result: FieldState;
        const {
          vectorAnalysisMaps = [],
          pinsGroups = [],
          ...field
        } = action.payload;

        if (isSameField(state.field, field)) {
          result = state;
        } else {
          result = {
            ...initialState,
            field,
          };
        }

        result = produce(result, (draft) => {
          if (draft.field) {
            if (draft.field.vectorAnalysisMaps) {
              draft.field.vectorAnalysisMaps.push(...vectorAnalysisMaps);
            } else {
              draft.field.vectorAnalysisMaps = vectorAnalysisMaps;
            }

            if (draft.field.pinsGroups) {
              draft.field.pinsGroups.push(...pinsGroups);
            } else {
              draft.field.pinsGroups = pinsGroups;
            }
          }
        });

        return result;
      })
      .addCase(fetchSatelliteImagesGeoMaps.pending, (state, action) => {
        const { isRawType, geoMapTypes, uuids } = action.meta.arg;
        const imagesToUpdate = (state.field?.satelliteImages || []).filter(({ uuid }) => {
          return uuids.includes(uuid);
        });
        const loadStatusKeys = geoMapTypes.map((geoMapType) => {
          return getGeoMapLoadStatusKey({ isRawType, geoMapType });
        });

        imagesToUpdate.forEach((image) => {
          loadStatusKeys.forEach((loadStatusKey) => {
            if (loadStatusKey && image[loadStatusKey] !== AssetLoadStatus.success) {
              image[loadStatusKey] = AssetLoadStatus.loading;
            }
          });
        });
      })
      .addCase(fetchSatelliteImagesGeoMaps.fulfilled, (state, action) => {
        const { isRawType, geoMapTypes, uuids } = action.meta.arg;
        const imagesToUpdate = (state.field?.satelliteImages || []).filter(({ uuid }) => {
          return uuids.includes(uuid);
        });
        const loadStatusKeys = geoMapTypes.map((geoMapType) => {
          return getGeoMapLoadStatusKey({ isRawType, geoMapType });
        });

        imagesToUpdate.forEach((image) => {
          const geoMaps = action.payload?.[image.uuid] || [];

          loadStatusKeys.forEach((loadStatusKey) => {
            if (loadStatusKey && image[loadStatusKey] === AssetLoadStatus.loading) {
              image[loadStatusKey] = geoMaps.length
                ? AssetLoadStatus.success
                : AssetLoadStatus.noData;

              // Ensure that geoMaps are not duplicated
              const uniqueGeoMaps = new Map(
                (image.geoMaps || []).map((geoMap) => [geoMap.shortName, geoMap]),
              );

              geoMaps.forEach((geoMap) => {
                uniqueGeoMaps.set(geoMap.shortName, geoMap);
              });

              image.geoMaps = Array.from(uniqueGeoMaps.values());
            }
          });
        });
      })
      .addCase(fetchBoundaryFeature.fulfilled, (state, action) => {
        if (isSameField<TransformedField>(state.field, {
          _type: AssetType.field,
          uuid: action.meta.arg.fieldUuid,
        })) {
          state.feature.record = action.payload;
        }
      })
      .addCase(deleteAsset.fulfilled, (state, action) => {
        if (state.field) {
          // FIXME: find out how to infer types
          state.field[action.payload.assetGroupType] = (
            state.field[action.payload.assetGroupType] as any[]
          )?.filter(({ uuid }) => {
            return uuid !== action.payload.uuid;
          });

          state.field.pinsGroups = state.field.pinsGroups?.filter(({ uuid }) => {
            return uuid !== action.payload.uuid;
          });
        }
      })
      .addCase(renameAsset.fulfilled, (state: FieldState, action) => {
        if (state.field) {
          // FIXME: find out how to infer types
          state.field[action.payload.assetGroupType] = (
            state.field[action.payload.assetGroupType] as any[]
          )?.map((asset) => {
            if (asset.uuid !== action.payload.uuid) {
              return asset;
            }

            return {
              ...asset,
              name: action.payload.name,
            };
          });
        }
      })
      .addCase(fetchVamapAssets.fulfilled, (state, action) => {
        if (state.field && isSameField<TransformedField>(state.field, {
          _type: AssetType.field,
          uuid: action.meta.arg.fieldUuid,
        })) {
          state.field.satelliteImages = [
            ...(state.field.satelliteImages || []),
            ...(action.payload?.satelliteImages || []),
          ];

          state.field.vectorAnalysisMaps = [
            ...(state.field.vectorAnalysisMaps || []),
            ...(action.payload?.vectorAnalysisMaps || []),
          ];

          state.field.soilDatasets = [
            ...(state.field.soilDatasets || []),
            ...(action.payload?.soilDatasets || []),
          ];

          state.field.yieldDatasets = [
            ...(state.field.yieldDatasets || []),
            ...(action.payload?.yieldDatasets || []),
          ];

          state.field.asAppliedDatasets = [
            ...(state.field.asAppliedDatasets || []),
            ...(action.payload?.asAppliedDatasets || []),
          ];

          state.field.topographyMaps = [
            ...(state.field.topographyMaps || []),
            ...(action.payload?.topographyMaps || []),
          ];
        }
      })
      .addCase(fetchAssetsGroup.pending, (state, action) => {
        const {
          assetsGroupType,
          farmUuid,
          fieldUuid,
        } = action.meta.arg;
        const field: TransformedField = {
          _type: AssetType.field,
          uuid: fieldUuid,
          farmUuid,
        };
        let result: FieldState;

        if (isSameField(state.field, field)) {
          result = state;
        } else {
          result = {
            ...initialState,
            field,
          };
        }

        result = produce(result, (draft) => {
          draft.loading[assetsGroupType] = LoadStatus.loading;
        });

        return result;
      })
      .addCase(fetchAssetsGroup.fulfilled, (state, action) => {
        const {
          assetsGroupType,
          fieldUuid,
        } = action.meta.arg;

        if (state.field && isSameField<TransformedField>(state.field, {
          _type: AssetType.field,
          uuid: fieldUuid,
        })) {
          state.loading[assetsGroupType] = LoadStatus.idle;
          state.fullyLoaded[assetsGroupType] = true;
          // FIXME: find out how to infer types
          state.field[assetsGroupType] = action.payload as any;
        }
      })
      .addCase(fetch3dMapShapePoints.pending, (state, action) => {
        if (state.field?.threeDimensionalMaps) {
          state.field.threeDimensionalMaps = state.field.threeDimensionalMaps.map((map3d) => {
            let result = map3d;

            if (map3d.uuid === action.meta.arg.map3dUuid) {
              result = {
                ...result,
                status: AssetLoadStatus.loading,
              };
            }

            return result;
          });
        }
      })
      .addCase(fetch3dMapShapePoints.rejected, (state, action) => {
        if (state.field?.threeDimensionalMaps) {
          state.field.threeDimensionalMaps = state.field.threeDimensionalMaps.map((map3d) => {
            let result = map3d;

            if (map3d.uuid === action.meta.arg.map3dUuid) {
              result = {
                ...result,
                pointsStored: false,
                status: action.payload === RemoteAssetStatus.deleted
                  ? AssetLoadStatus.noData
                  : AssetLoadStatus.error,
              };
            }

            return result;
          });
        }
      })
      .addCase(fetch3dMapShapePoints.fulfilled, (state, action) => {
        const uuid = action.payload?.uuid;

        if (state.field?.threeDimensionalMaps && uuid) {
          state.field.threeDimensionalMaps = state.field.threeDimensionalMaps.map((map3d) => {
            let result = map3d;

            if (map3d.uuid === uuid) {
              result = {
                ...result,
                pointsStored: true,
                status: AssetLoadStatus.success,
              };
            }

            return result;
          });
        }
      })
      .addCase(saveBoundaryFeature.pending, (state, action) => {
        if (isSameField(state.field, {
          _type: AssetType.field,
          uuid: action.meta.arg.fieldUuid,
        })) {
          state.feature.saving = true;
        }
      })
      .addCase(saveBoundaryFeature.fulfilled, (state, action) => {
        if (isSameField(state.field, {
          _type: AssetType.field,
          uuid: action.meta.arg.fieldUuid,
        })) {
          state.feature.saving = false;
          state.feature.record = null;
        }
      })
      .addCase(save3dMap.fulfilled, (state, action) => {
        if (state.field) {
          const ind = (state.field.threeDimensionalMaps || []).findIndex(({ uuid }) => {
            return uuid === action.payload.uuid;
          });

          if (ind === -1) {
            state.field.threeDimensionalMaps = [
              action.payload,
              ...(state.field.threeDimensionalMaps || []),
            ];
          } else if (state.field.threeDimensionalMaps) {
            state.field.threeDimensionalMaps[ind] = action.payload;
          }
        }
      })
      .addCase(saveVamap.fulfilled, (state, action) => {
        if (state.field) {
          const ind = (state.field.vectorAnalysisMaps || []).findIndex(({ uuid }) => {
            return uuid === action.payload.uuid;
          });

          if (ind !== -1 && state.field.vectorAnalysisMaps) {
            if (action.payload.type) {
              state.field.vectorAnalysisMaps[ind].type = action.payload.type;
            }

            if (action.payload.zonesMapGeojson) {
              state.field.vectorAnalysisMaps[ind].zonesMapGeojson = prepareZonesMapJson(
                JSON.parse(action.payload.zonesMapGeojson),
              );
            }

            if (action.payload.attributesJson) {
              state.field.vectorAnalysisMaps[ind].attributes = prepareZonesMapJson(
                JSON.parse(action.payload.attributesJson),
              );
            }

            if (action.payload.geoMaps) {
              state.field.vectorAnalysisMaps[ind].geoMaps = action.payload.geoMaps;
            }
          }
        }
      })
      .addCase(orderPlanetImage.pending, (state, action) => {
        if (state.field && isSameField(state.field, {
          _type: AssetType.field,
          uuid: action.meta.arg.fieldUuid,
        })) {
          state.field.satelliteImages = state.field.satelliteImages?.map((image) => {
            if (image.uuid !== action.meta.arg.satelliteImageUuid) {
              return image;
            }

            return {
              ...image,
              status: 'ORDERED',
            };
          });
        }
      })
      .addCase(orderPlanetImage.fulfilled, (state, action) => {
        if (state.field && action.payload && isSameField(state.field, {
          _type: AssetType.field,
          uuid: action.meta.arg.fieldUuid,
        })) {
          state.field.satelliteImages = state.field.satelliteImages?.map((image) => {
            if (image.uuid !== action.meta.arg.satelliteImageUuid) {
              return image;
            }

            return {
              ...image,
              status: 'VALIDATED',
            };
          });
        }
      })
      .addCase(updateUserData.fulfilled, () => {
        return initialState;
      })
      .addCase(updateVectorAnalysisMap.fulfilled, (state, action) => {
        if (state.field?.vectorAnalysisMaps) {
          const ind = state.field.vectorAnalysisMaps.findIndex(({ uuid }) => {
            return uuid === action.payload.uuid;
          });

          if (ind !== -1) {
            state.field.vectorAnalysisMaps[ind] = {
              ...state.field.vectorAnalysisMaps[ind],
              name: action.payload.name,
            };
          }
        }
      })
      .addCase(finalizeEquationMapGeneration, (state, action) => {
        if (state.field?.equationMaps) {
          const ind = state.field.equationMaps.findIndex(({ uuid }) => {
            return uuid === action.payload.uuid;
          });

          if (ind === -1) {
            state.field.equationMaps = [
              action.payload,
              ...state.field.equationMaps,
            ];
          } else if (state.field.equationMaps) {
            state.field.equationMaps[ind] = action.payload;
          }
        }
      })
      .addCase(updateEquationMap.fulfilled, (state, action) => {
        const update = action.payload;

        if (update && state.field?.equationMaps) {
          const ind = state.field.equationMaps.findIndex(({ uuid }) => {
            return uuid === update.uuid;
          });

          if (ind !== -1) {
            state.field.equationMaps[ind] = {
              ...state.field.equationMaps[ind],
              name: update.name,
              type: update.type,
            };
          }
        }
      })
      .addCase(fetchSynchronizedJohnDeereField.fulfilled, (state, action) => {
        if (isSameField<TransformedField>(state.field, {
          _type: AssetType.field,
          uuid: action.meta.arg.fieldUuid,
        })) {
          state.jdField = action.payload ?? false;
        }
      })
      .addMatcher(fieldsAPI.endpoints.renameField.matchFulfilled, (state, action) => {
        if (state.field && isSameField<TransformedField>(state.field, {
          _type: AssetType.field,
          uuid: action.meta.arg.originalArgs.uuid,
        })) {
          state.field.name = action.meta.arg.originalArgs.name;
        }
      })
      .addMatcher(fieldsAPI.endpoints.setFieldLabels.matchFulfilled, (state, action) => {
        if (state.field && isSameField<TransformedField>(state.field, {
          _type: AssetType.field,
          uuid: action.meta.arg.originalArgs.uuid,
        })) {
          state.field.labels = action.meta.arg.originalArgs.labels;
        }
      });
  },
});

const {
  setNoMonitoring,
  updateData,
  updateAssetsCategory,
} = fieldSlice.actions;

export const {
  addPin,
  updatePin,
  updatePins,
  deletePin,
  addPinComment,
  setFeatureEdit,
  resetFeatureEdit,
} = fieldSlice.actions;

export default fieldSlice.reducer;
