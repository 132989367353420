import { useState } from 'react';
import { Auth } from '@aws-amplify/auth';
import { closeSnackbar } from 'notistack';

import useTokensBroker from './useTokensBroker';

export default function useAuthenticate(props?: {
  onSignOutSuccess?: () => void;
}) {
  const [authenticated, setAuthenticated] = useState(false);
  const [authenticationCompleted, setAuthenticationCompleted] = useState(false);

  const { sendGetTokensMessage, sendSignOutMessage } = useTokensBroker({
    onTokensReceived: (tokensExist) => {
      setAuthenticated(tokensExist);
      setAuthenticationCompleted(true);
    },
    onTokensError: () => {
      closeSnackbar();
      setAuthenticated(false);
      setAuthenticationCompleted(true);
    },
    onSignOutSuccess: () => {
      setAuthenticated(false);
      setAuthenticationCompleted(true);
      props?.onSignOutSuccess?.();
    },
  });

  const authenticate = () => {
    sendGetTokensMessage();
  };

  const signOut = () => {
    closeSnackbar();
    sendSignOutMessage();
    Auth.signOut();
  };

  return {
    authenticate,
    signOut,
    authenticated,
    authenticationCompleted,
  };
}
